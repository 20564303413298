import { Icon } from 'components/icon/icon.component'
import style from './menuItem.module.css'
import { Text } from 'components/text/text.component'
import { View } from 'components/view/view.component'
import { MenuItem as MenuItemType } from 'model/menuItem.model'
import { ReactNode, useState } from 'react'

type MenuItemProps = {
    id?: string,
    iconName?: string,
    text?: string,
    onClick?: (id: string)=>void,
    link?: string,
    subItems?: MenuItemType[],
    hide?: boolean,
    key?: string | number
}

export function MenuItem({iconName, id, text, onClick, link, subItems, hide, key}: MenuItemProps){
    const [collapsed, setCollapsed] = useState(false)
    function handleClick(){
        if(subItems) {
            setCollapsed(!collapsed)
            return 
        }
        if(link) window.location.href = link
        if(onClick && id) onClick(id)
    }
    if(hide) return <></>
    return (
        <Link isALink={!!link && (!subItems || subItems.length <= 0)} link={link} key={key}>
            <View className={`${style.Item} ${(!subItems || subItems.length <= 0) && style.Clickable}`} key={id} onClick={handleClick}>
                <View className={style.Content}>
                    {iconName && <Icon fontSize='small' >{iconName}</Icon>}
                    {text && <Text type='xs' weight='bold' className={style.Title}>{text}</Text>}
                    {(subItems && subItems.length > 0) && <Icon>{ collapsed ? 'expand_less' : 'expand_more'}</Icon>}
                </View>
                {(subItems && collapsed) && <View className={style.SubItens}>
                    {subItems.map(_ => <MenuItem key={_.id} id={_.id} iconName={_.icon} text={_.title} link={_.link} />)}
                </View>}
            </View>
        </Link>
    )
}

function Link({isALink, link, children}: {isALink?: boolean, link?: string, children?: ReactNode}){
    if(isALink) return <a href={link}>{children}</a>
    return <>{children}</>
}