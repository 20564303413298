import { useState } from "react"
import { pdf } from '@react-pdf/renderer';
import { downloadFile } from 'services/file.service';
import { ReportData } from "model/reportData.model";
import { PdfReportTemplate } from 'reports/templates/pdf.report.template'

type useIndicatorPDFReportProps = {
    data: ReportData,
    fileName: string
}

export function usePDFReport({data, fileName}: useIndicatorPDFReportProps){
    // const [instance, updateInstance] = usePDF({document: <IndicatorPDFReport data={data} />})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | undefined>()
  
    async function download(){
      try{
        setLoading(true)
        const blob = await pdf(PdfReportTemplate({data})).toBlob()
        const name = `${fileName}.pdf`
        await downloadFile(blob, name)
      }catch(error){
        setError((error as Error).message)
      }
      setLoading(false)
    }
    return {
      loading,
      error,
      download
    }
  }