import { RoomReservationReport } from "model/reports/roomReservation.report.model"
import { FilterType } from "model/filter.model"
import { useHTTPClient } from "services/httpClient.service"
import { host } from "config/api.config"

const url = `${host}/report/room-reservation`
export function useGetRoomReservationReportApi(){

    const {data, loading, error, get} = useHTTPClient<RoomReservationReport[]>(`${url}`)

    async function getApi(filter?: FilterType){
        if(!filter?.rooms || filter.rooms.length <= 0) return
        const roomsStrings = filter?.rooms?.map(_ => `rooms=${_.id}`)
        const roomString = roomsStrings?.join('&')
        const u = `${url}?startDate=${filter?.startDate?.toISOString()}&endDate=${filter?.endDate?.toISOString()}&${roomString}`
        console.log(u)
        const response = await get({_url: u})
        return response
    }

    return {
        loading,
        error: error,
        get: getApi,
        data
    }
}