import { Filters } from 'components/filters/filters.component'
import style from './graphic.module.css'
import { Page } from "components/page/page.component"
import { View } from "components/view/view.component"
import { Text } from 'components/text/text.component'
import { useGetRoomReservationGraphicDataApi } from 'api/graphics.api'
import { Skeleton } from 'components/skeleton'
import { FilterType } from 'model/filter.model'
import { useEffect, useState } from 'react'
import { Graphic } from 'components/graphic/graphic.santander.component'
import { difference, getDaysBefore } from 'utils/date.util'
import { useColorTheme } from 'services/theme.service'

type GraphicPageProps = {
    companyId?: string,
    indicatorId?: string
}

export function GraphicPage({companyId}: GraphicPageProps){
    
    const {COLOR} = useColorTheme()
    const {data, get, loading} = useGetRoomReservationGraphicDataApi()
    const [filter, setFilter] = useState<FilterType | undefined>(undefined)

    function handleOnFilterChange(filter: FilterType){
        setFilter(filter)
    }

    useEffect(()=>{
        if(!companyId) return
        if(!filter?.startDate) return
        if(!filter?.endDate) return
        if(!filter?.rooms) return
        if(difference(filter.endDate, filter.startDate) > 1) return
        const _filter = {
            rooms: filter.rooms,
            startDate: filter.startDate,
            endDate: filter.endDate
        }
        get(_filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filter, companyId])

    return <Page menu header title="Gráfico Reserva e Ocupação de Salas">
        <View className={style.Content}>
            <Filters 
                companyId={companyId} 
                showRooms 
                onlyOneRoom
                showStartDate 
                showEndDate 
                dateType='datetime' 
                initialStartDate={getDaysBefore( new Date(), 1)} 
                initialEndDate={new Date()}
                onChange={handleOnFilterChange}
            />
            {loading 
            ? <Skeleton className={style.GraphicContainer} color='bg3' />
            :<View className={style.GraphicContainer}>
                {!filter?.rooms || filter.rooms.length <= 0
                ? <Text type='h3'>Selecione ao menos uma sala</Text>
                : !filter.startDate || !filter.endDate
                ? <Text type='h3'>Selecione as datas</Text>
                : difference(filter.endDate, filter.startDate) > 1
                ? <Text type='h3'>Selecione o período máximo de um dia</Text>
                : !data || data.length <= 0
                ? <Text type='h3'>Nenhum dado encontrado</Text>
                :<View className={style.GraphicContent}>
                    <View className={style.GraphicArea}>
                        <Graphic 
                            labels={data.map(_ => new Date(_.date).toLocaleString())}
                            datasets={[
                                {
                                    label: 'Reservada',
                                    data: data.map(_ => _.reserved? 'ON' : 'OFF'),
                                    steped: true,
                                    borderColor: COLOR.blue_primary_color,
                                    backgroundColor: COLOR.blue_primary_color
                                },
                                {
                                    label: 'Ocupada',
                                    data: data.map(_ => _.occupied ? 'ON': 'OFF'),
                                    steped: true,
                                    borderColor: COLOR.red_primary_color,
                                    backgroundColor: COLOR.red_primary_color
                                }
                            ]}
                            type='category'
                            showLegends
                            legends={[
                                {
                                    label: 'Reservada',
                                    color: COLOR.blue_primary_color
                                },
                                {
                                    label: 'Ocupada',
                                    color: COLOR.red_primary_color
                                }
                            ]}
                        />
                    </View>
                </View>}
            </View>
            }
        </View>
    </Page>
}