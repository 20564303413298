import { Document, Page, View, Image, Text, StyleSheet } from "@react-pdf/renderer";
import logo from 'assets/images/ARO Tech - logo vetor.png'
import { ReportData } from "model/reportData.model";
import { ReactElement } from "react";

type PDFReportTemplateProps ={
    data?: ReportData
}

const styles = StyleSheet.create({
  document: { width: '100%'},
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    width: '100%',
    alignItems: 'center'
  },
  section: {
    marginBottom: 10,
    width: '90%'
  },
  headerTitleContent: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: '50px',
    alignItems: 'center'
  },
  image: {
    position: 'absolute',
    width: '80px',
    aspectRatio: 2,
    marginLeft: '10px'
  },
  headerTitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: 18
  },
  table: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tableHeader: {
    backgroundColor: '#F3F4F6',
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  tableBody: {
    fontSize: 10,
    fontWeight: 'bold',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  tableCell: {
    flex: 1,
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    border: '1px solid #000000',
    padding: 5
  },
});

export function PdfReportTemplate({data}:PDFReportTemplateProps): ReactElement{
    return <Document style={styles.document}>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <View style={styles.headerTitleContent}>
                    <Image src={logo} style={styles.image} />
                    <Text style={styles.headerTitle}>Relatório de Indicadores</Text>
                </View>
            </View>

            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            {data?.titles.map((_,index) => <Text key={index} style={styles.tableCell}>{_}</Text>)}
                        </View>
                    </View>
                    <View style={styles.tableBody}>
                        { !data?.rows
                        ? <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>Nenhum dado encontrado.</Text>
                        </View>
                        :data.rows.map((row, index) => (
                            <View key={index} style={styles.tableRow}>
                              {row.map((_, index) => <Text key={index} style={styles.tableCell}>{ _ }</Text>)}
                            </View>
                        ))}
                    </View>
                </View>
            </View>
        </Page>
    </Document>
}