import { ReportData } from "model/reportData.model";
import { RoomReservationReport } from "model/reports/roomReservation.report.model";

export function createRoomReservationReportData(data?: RoomReservationReport[]): ReportData {
    const rows = data?.map(_ => {
        return [
            _.room ?? '---' ,
            _.startDate.toLocaleString() ?? '---' ,
            _.endDate.toLocaleString() ?? '---',
            _.reservedBy ?? '---',
            (_.participants ?? '---').toString(),
            _.status ?? '---'
        ]
    }) ?? []
    return {
        titles: ['Sala', 'Início', 'Fim', 'Reservado por', 'Participantes', 'Status'],
        rows: rows
    }
}