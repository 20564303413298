import { host } from "config/api.config";
import { FilterType } from "model/filter.model";
import { RoomReservationGraphicData } from "model/graphics/roomReservationGraphicData.model";
import { useHTTPClient } from "services/httpClient.service";

const url = `${host}/graphic/room-reservation`

export function useGetRoomReservationGraphicDataApi(){
    const {data, loading, error, get} = useHTTPClient<RoomReservationGraphicData[]>(url)

    async function getApi(filter: FilterType){
        if(!filter?.rooms || filter.rooms.length <= 0) return
        const u = `${url}?startDate=${filter?.startDate?.toISOString()}&endDate=${filter?.endDate?.toISOString()}&room=${filter.rooms[0].id}`
        const response = await get({_url: u})
        return response
    }

    return {
        loading,
        error: error,
        get: getApi,
        data
    }
}