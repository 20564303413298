import { Page } from "components/page/page.component"
import { View } from "components/view/view.component"
import { Text } from 'components/text/text.component'
import style from './reservationReport.module.css'
import { Skeleton } from "components/skeleton"
import { Table, TableBody, TableCell, TableHead, TableRow } from "components/table/table.component"
import { useEffect, useState } from "react"
import { getDaysBefore } from "utils/date.util"
import { Button } from "components/button/button.component"
import { Icon } from "components/icon/icon.component"
import { Filters } from "components/filters/filters.component"
import { FilterType } from "model/filter.model"
import { useGetRoomReservationReportApi } from "api/reports.api"
import { usePDFReport } from "utils/pdfReportHook.util"
import { useExcelReport } from "utils/excelReportHook.util"
import { createRoomReservationReportData } from "reports/roomReservation.data.report"

type ReservationReportPageProps = {
    companyId?: string
}
export function ReservationReportPage({companyId}: ReservationReportPageProps){
    const [filter, setFilter] = useState<FilterType | undefined>()

    const {loading, get, data } = useGetRoomReservationReportApi()

    const reportData = createRoomReservationReportData(data?.map(_ => {return {..._, startDate: new Date(_.startDate), endDate: new Date(_.endDate)}}))

    const {loading: loadingExcelReport, download: downloadExcelReport} = useExcelReport({data: reportData ,fileName: 'AROTech - Relatorio de reserva de salas'})
    const {loading: loadingPDFReport, download: downloadPDFReport} = usePDFReport({ data: reportData, fileName: 'AROTech - Relatorio de reserva de salas'} )
    
    function handleOnFilterChange(filter: FilterType){
        setFilter(filter)
    }

    useEffect(()=>{
        if(!companyId) return
        if(!filter?.startDate) return
        if(!filter?.endDate) return
        if(!filter?.rooms) return
        const _filter = {
            rooms: filter.rooms,
            startDate: filter.startDate,
            endDate: filter.endDate
        }
        get(_filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filter, companyId])
    
    return <Page menu header title="Relatório Reservas de Salas">
        <View className={style.Content}>
            
        <View className={style.ContentHeader}>
            <Filters 
                companyId={companyId} 
                showRooms 
                showStartDate
                initialStartDate={getDaysBefore(new Date(), 1)}
                showEndDate
                initialEndDate={new Date()}
                dateType="datetime" 
                onChange={handleOnFilterChange}
            />
        </View>
        <View className={style.ExportSection}>
            <View className={style.ExportSectionButtons}>
                <Button variant='contained' startIcon={<Icon className={style.Icons}>picture_as_pdf</Icon>}
                    onClick={downloadPDFReport}
                    disabled={!data || data.length <= 0}
                    loading={loadingPDFReport}
                >
                    Exportar para PDF
                </Button>
                <Button 
                    variant='contained' 
                    startIcon={<Icon className={style.Icons}>border_all</Icon>}
                    loading={loadingExcelReport}
                    onClick={downloadExcelReport}
                    disabled={!data || data.length <= 0}
                >
                    Exportar para Excel
                </Button>
            </View>
        </View>
    {loading
    ? <Skeleton className={style.TableContainer} />
    :<View className={style.TableContainer}>
        <View className={style.TableContent}>
                <Table >
                    <TableHead>
                        <TableRow>
                            {reportData.titles.map(_ => <TableCell><Text type='h4'>{_}</Text></TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        (!filter?.rooms || filter?.rooms.length <= 0)
                        ?<TableRow className={style.Content}>
                            <TableCell colSpan={7}>
                                <Text type='sm'>Selecione uma sala</Text>
                            </TableCell>
                        </TableRow>
                        : (!data || data?.length <= 0)
                        ? <TableRow className={style.Content}>
                            <TableCell colSpan={7}>
                                <Text type='sm'>Nenhum Lançamento encontrado</Text>
                            </TableCell>
                        </TableRow>
                        : reportData.rows.map((row, index) => <TableRow key={index}>
                            {row.map(_ => <TableCell><Text type='sm'>{ _ }</Text></TableCell>)}
                        </TableRow>)
                        }
                    </TableBody>
                </Table>
        </View>
    </View>
    }
    </View>
    </Page>
}