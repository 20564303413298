import { CSSProperties, ReactNode } from "react"
import styles from './text.component.module.css'

type TextProps = {
    type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
    children?: ReactNode,
    weight?: 'regular' | 'medium' | 'semiBold' | 'bold',
    className?: string,
    subtext?: boolean,
    onClick?: ()=>void,
    href?: string,
    style?: CSSProperties
}

export function Text({type, children, weight, className, subtext, onClick, href, style}: TextProps){
    let _type = styles.md
    let component 
    switch (type) {
        case 'h1':
            component = <h1 className={`${weight ? styles[weight]: ''} ${subtext ? styles.subtext : styles.text} ${styles.h1} ${onClick? styles.clickable : ''} ${className ? className: ''}`} onClick={onClick} style={style}>{ children }</h1>
            break;
        case 'h2':
            component = <h2 className={`${weight ? styles[weight]: ''} ${subtext ? styles.subtext : styles.text} ${styles.h2} ${onClick? styles.clickable : ''} ${className? className: ''} `} onClick={onClick} style={style}>{ children }</h2>
            break;
        case 'h3':
            component = <h3 className={`${weight ? styles[weight]: ''} ${subtext ? styles.subtext : styles.text} ${styles.h3} ${onClick? styles.clickable : ''} ${className? className: ''} `} onClick={onClick} style={style}>{ children }</h3>
            break;
        case 'h4':
            component = <h4 className={`${weight ? styles[weight]: ''} ${subtext ? styles.subtext : styles.text} ${styles.h4} ${onClick? styles.clickable : ''} ${className? className: ''} `} onClick={onClick} style={style}>{ children }</h4>
            break;
        case 'h5':
            component = <h5 className={`${weight ? styles[weight]: ''} ${subtext ? styles.subtext : styles.text} ${styles.h5} ${onClick? styles.clickable : ''} ${className? className: ''} `} onClick={onClick} style={style}>{ children }</h5>
            break;
        case 'h6':
            component = <h6 className={`${weight ? styles[weight]: ''} ${subtext ? styles.subtext : styles.text} ${styles.h6} ${onClick? styles.clickable : ''} ${className? className: ''} `} onClick={onClick} style={style}>{ children }</h6>
            break;
        case 'xxs':
            _type = styles.xxs
            break;
        case 'xs':
            _type = styles.xs
            break;
        case 'sm':
            _type = styles.sm
            break;
        case 'md':
            _type = styles.md
            break;
        case 'lg':
            _type = styles.lg
            break;
        case 'xl':
            _type = styles.xl
            break;
        case 'xxl':
            _type = styles.xxl
            break;
        default:
            break;
    }
    if(!component) component = <p className={`${weight && styles[weight]} ${subtext ? styles.subtext : styles.text} ${_type} ${onClick? styles.clickable : ''} ${className ? className: ''} `} onClick={onClick} style={style}> { children } </p>
    if(href) return <a href={href}>{component}</a>
    return component
}