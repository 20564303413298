import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import styles from './roomReservationCard.module.css'
import { Icon } from "components/icon/icon.component";
import { toHourMinuteString } from "utils/date.util";

type RoomReservationCardProps = {
    title?: string,
    reserved?: boolean,
    occupied?: boolean,
    color?: string,
    reservedBy?: string,
    reserveStartAt?: Date,
    reserveEndAt?: Date
}

export function RoomReservationCard({title = 'Sala 1', reserved = false, occupied = false, color, reservedBy, reserveStartAt, reserveEndAt}: RoomReservationCardProps){
    const _color = color ?? 'blue'
    return <View className={styles.Card} style={{backgroundColor: `var(--${_color}-primary-color`, color: `var(--${_color}-primary-color-contrast)`}}>
        <View className={styles.Content}>
            <View className={styles.Header}>
                <View className={styles.Title}>
                    <Text type="h3" style={{color: `var(--${_color}-primary-color-contrast)`}}>{title}</Text>
                </View>
                <View className={styles.Icon} style={{backgroundColor: `var(--${_color}-primary-color-contrast)`}}>
                    <Icon style={{color: `var(--${_color}-primary-color`}}>{occupied ? 'door_front' :'door_open'}</Icon>
                </View>
            </View>
            <View className={styles.Body} >
                <Text type="h4" style={{color: `var(--${_color}-primary-color-contrast)`}}>Status:</Text>
                <View className={styles.Row}>
                    <Icon fontSize="small" style={{color: `var(--${_color}-primary-color-contrast)`}}>{reserved ? 'lock': 'lock_open'}</Icon>
                    <Text type="md" style={{color: `var(--${_color}-primary-color-contrast)`}}>{reserved ? `Reservada - ${toHourMinuteString(reserveStartAt)} a ${toHourMinuteString(reserveEndAt)}`: 'Não Reservada'}</Text>
                </View>
                {(reserved && reservedBy) && <View className={styles.Row}>
                <Icon fontSize="small" style={{color: `var(--${_color}-primary-color-contrast)`}}>person</Icon>
                    <Text type="sm" style={{color: `var(--${_color}-primary-color-contrast)`}}>{reserved ? `Por ${reservedBy}`: ''}</Text>
                </View>}
                <View className={styles.Row}>
                    <Icon fontSize="small" style={{color: `var(--${_color}-primary-color-contrast)`}}>{occupied ? 'door_front': 'door_open'}</Icon>
                    <Text type='md' style={{color: `var(--${_color}-primary-color-contrast)`}}>{occupied ? 'Ocupada': 'Não Ocupada'}</Text>
                </View>
            </View>
        </View>
    </View>
}