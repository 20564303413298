import { BarElement, CategoryScale, Chart, Filler, Legend, LineElement, LinearScale, PointElement, Title, Tooltip, ChartOptions } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar, Line } from "react-chartjs-2";
import style from './gruphic.component.module.css'
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

type GraphicLegend = {
    label: string,
    color: string
}

type GraphicDataset = {
    label: string,
    data: any[],
    borderColor?: string,
    backgroundColor?: string,
    steped?: boolean
}

type GraphicProps = {
    labels: string[],
    datasets: GraphicDataset[],
    type: 'bar' | 'line' | 'category',
    showLegends?: boolean,
    legends?: GraphicLegend[]
}

export function Graphic({datasets, labels, type, showLegends, legends}: GraphicProps){

    const options: ChartOptions< 'bar' | 'line' > = {
        responsive: true,
        maintainAspectRatio: false,
        plugins:{
            datalabels: {
                display: false,
                anchor: 'end',
                align: 'end',
                font: { weight: 'bold'},
            },
            legend:{
                display: false,
            }
        },
        layout:{
            padding: {
                top: (type === 'line') ? undefined : 20,
            }
        },
        scales:{
            y:{
                type: 'category',
                labels: ['ON', 'OFF'],
                offset: true,
                position: 'left',
                stack: 'demo',
                stackWeight: 1,
            },
            x: {
                grid: { display: false }
            }
        }
    }

    const _data = {
        labels: labels,
        datasets: datasets
    }

    return <View className={style.GraphicComponent}>
        <View className={style.GraphicContent}>
            {
                type === 'line' || type === 'category'
                ? <Line data={_data} options={options} />
                :<Bar 
                    options= {options}
                    data={_data}      
                />
            }
        </View>
        <View className={style.LegendContent} hide={!showLegends}>
            {legends && legends.length > 0
            ? legends.map(_ => <View className={style.Legend}> 
                <View className={style.LegendColorBox} style={{backgroundColor: _.color}}> </View>
                <Text>{_.label}</Text>
            </View>)
            : <></>
            }
        </View>
    </View>
}