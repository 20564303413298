import { useState } from "react";
import { SheetFactory } from "services/excel.service";
import { downloadFile } from "services/file.service";
import { ReportData } from "model/reportData.model"

type UseExcelReportProps = {
    data: ReportData,
    fileName: string
}

export function useExcelReport({data, fileName}: UseExcelReportProps){

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | undefined>()
    const excel = new SheetFactory('Relatório de Indicadores')

    function createReport(){
        excel.addTitleRow(data.titles)
        excel.addRows(data.rows)
    }

    async function download(){
        try{
            setLoading(true)
            createReport()
            const b = await excel.finishSheet()
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        
            const blob = new Blob([b], { type: fileType });
            const name = `${fileName}.xlsx`;
            await downloadFile(blob, name)
        }
        catch(error){
            setError((error as Error).message)
        }
        setLoading(false)
    }

    return {
        download,
        loading,
        error
    }

}