import { differenceInDays, differenceInMinutes as ApiDifferenceInMinutes, differenceInMonths, format, subDays, subMonths, subYears } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function toMonthYearString(date: Date){
    const d = new Date(date)
    const _date = new Date(d.getUTCFullYear(), d.getUTCMonth(), 15)
    const result = format(_date, 'MMMM/yyyy', { locale: ptBR })
    console.log(`to month/year`, date, _date, result)
    return result
}

export function toMonthYearSmallString(date: Date){
    const d = new Date(date)
    const _date = new Date(d.getUTCFullYear(), d.getUTCMonth(), 15)
    const result = format(_date, 'MMM/yyyy', { locale: ptBR })
    console.log(`to month/year`, date, _date, result)
    return result
}

export function toHourMinuteString(date?: Date){
    if(!date) return '-'
    return format(date, 'HH:mm', { locale: ptBR })
}

export function getOneYearBefore(date: Date): Date {
    return subYears(date, 1)
}

export function getOneMonthBefore(date: Date): Date {
    return subMonths(date, 1)
}

export function getMonthsBefore(date: Date, sub: number): Date {
    return subMonths(date, sub)
}

export function getDaysBefore(date: Date, sub: number): Date {
    return subDays(date, sub)
}

export function monthsDifference(date1: Date, date2: Date){
    return differenceInMonths(date1, date2)
}

export function difference(date1: Date, date2: Date){
    return differenceInDays(date1, date2)
}

export function differenceInMinutes(date1: Date, date2: Date){
    return ApiDifferenceInMinutes(date1, date2)
}

export function sortByDate(a: Date, b: Date, ascending: boolean) {
    const comparison = a.getTime() - b.getTime();
    return ascending ? comparison : -comparison;
}