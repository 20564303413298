import { host } from "config/api.config";
import { Room } from "model/room.model";
import { useHTTPClient } from "services/httpClient.service";

const url = `${host}/room`

export function useGetRoomsApi(companyId: string){

    const {data, loading, error, get} = useHTTPClient<Room[]>(`${url}?companyId=${companyId}`)

    function getApi(){
        return get({})
    }

    return {
        loading,
        error,
        get: getApi,
        data
    }
}